import React, {useState} from 'react';
import CardContent from '../components/CardContent';
import SocialMediaIcons from '../components/SocialMediaIcons';
import ActiveMenuCard from '../components/ActiveMenuCard';
import MoreMenu from '../components/MoreMenu';

// https://codesandbox.io/p/sandbox/reverent-artem-34d6sp?file=%2Fsrc%2Fcomponents%2Fcard%2Findex.jsx%3A42%2C5&workspaceId=c1d15819-4965-41d1-80ad-825f89bd6a35

const Sm = (props) => {
  const {
    id,
    title,
    dateCreated,
    photoUrl,
    displayName,
    body,
    hideBody,
    image,
    showImage,
    handleAction,
    switchPage,
    type,
    tags,
    menuOptions,
  } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const toggleSubmenu = () => {
    setOpenMenu(!openMenu);
  }

  const MoreMenuProps = {
    title,
    body,
    tags,
    toggleSubmenu,
    handleAction,
    openMenu,
    menuOptions
  }

  const showHide = openMenu ? 'opacity-0 pointer-events-none' : 'opacity-100';

  const cardContentProps = {
    photoUrl,
    displayName,
    showHide,
    id,
    title,
    body,
    hideBody,
    image,
    showImage,
    dateCreated,
    tagCount: type === 'ROOT' || type === 'TAGS' ? false : true,
    tags,
    switchPage,
  }

  return (<div className="w-full sm:w-96 sm:max-w-96 p-3">
    <div className="relative mb-1 bg-transparent border border-gray-200 rounded-lg shadow dark:bg-transparent dark:border-gray-700">
      {openMenu && (<ActiveMenuCard hideBody={hideBody} toggleSubmenu={toggleSubmenu}/>)}
      <MoreMenu {...MoreMenuProps}/>
      <CardContent {...cardContentProps} />
      {!hideBody && (
        <div className="pb-5">
          {SocialMediaIcons()}
        </div>
      )}
    </div>
  </div>);
} 

export default Sm;